// exports array of my roles
export const myRoles = [
  "Mobile App Developer",
  "Web Developer",
  "IOT Developer",
  "Data Scientist",
];

export const allBlogs = [
  {
    id: 1,
    title: "Opticon: Drowsiness Mitigation using IOT and AI",
    image: "images/blog/opticon-lp.jpg",
    filesource: "../../blogs/opticon.md",
    date: "26 June, 2024",
    author: "Maulana",
    category: "Projects",
  },
  {
    id: 2,
    title: "Greeny: Smart Mobile Apps for Urban Agriculture",
    image: "images/blog/greeny-lp.jpg",
    filesource: "../../blogs/greeny.md",
    date: "25 November, 2023",
    author: "Maulana",
    category: "Projects",
  },
  {
    id: 3,
    title: "Pita ID: Text Extraction from KTP using OCR",
    image: "images/blog/pitaID-lp.png",
    filesource: "../../blogs/pitaID.md",
    date: "11 December, 2023",
    author: "Maulana",
    category: "Business",
  },
  {
    id: 4,
    title: "Simak Gizi: Measuring Nutrition using Various Methods",
    image: "images/blog/simak-gizi-thumbnail.png",
    filesource: "../../blogs/simak-gizi.md",
    date: "25 December, 2023",
    author: "Maulana",
    category: "Projects",
  },
  {
    id: 5,
    title: "Insight: Realtime Monitoring and Analysis for Business",
    image: "images/blog/Insight-thumbnail.png",
    filesource: "../../blogs/insight.md",
    date: "25 October, 2024",
    author: "Maulana",
    category: "Reviews",
  },
  {
    id: 6,
    title: "Maissy: Maintenance Monitoring System for Manufacturing Industry",
    image: "images/blog/maissy-thumbnail.png",
    filesource: "../../blogs/maissy.md",
    date: "25 October, 2024",
    author: "Maulana",
    category: "Projects",
  },
  {
    id: 7,
    title: "Production Uniform: Monitoring System for Manufacturing Industry Uniform",
    image: "images/blog/production-uniform-thumbnail.png",
    filesource: "../../blogs/production-uniform.md",
    date: "25 October, 2024",
    author: "Maulana",
    category: "Projects",
  },
  {
    id: 8,
    title: "E-Report: Interactive Smartform for Reporting Activities",
    image: "images/blog/e-report-thumbnail.png",
    filesource: "../../blogs/e-report.md",
    date: "25 October, 2024",
    author: "Maulana",
    category: "Projects",
  },
  
  {
    id: 9,
    title: "Saling Bantu: Match Maker for UMKM and Investor",
    image: "images/blog/saling-bantu-thumbnail.png",
    filesource: "../../blogs/saling-bantu.md",
    date: "18 December, 2023",
    author: "Maulana",
    category: "Projects",
  },
  // {
  //   id: 10,
  //   title: "Amazing E Grocery: Web Based Grocery Shopping",
  //   image: "images/blog/3.svg",
  //   filesource: "../../blogs/things-to-know-about-startup-business.md",
  //   date: "06 February, 2020",
  //   author: "Bolby",
  //   category: "Business",
  // },
  {
    id: 11,
    title: "Meteranku: Web Based Water Usage Monitoring",
    image: "images/blog/1.svg",
    filesource: "../../blogs/best-app-development-tool.md",
    date: "09 February, 2020",
    author: "Bolby",
    category: "Reviews",
  },
  {
    id: 12,
    title: "Coin Batam: Cryptocurrency Trend Monitoring",
    image: "images/blog/coin-batam-thumbnail.png",
    filesource: "../../blogs/coin-batam.md",
    date: "28 August, 2022",
    author: "Maulana",
    category: "Projects",
  },
  {
    id: 13,
    title: "Rumpi App: Web Based Chatting App",
    image: "images/blog/rumpi-app-thumbnail.png",
    filesource: "../../blogs/rumpi-app-thumbnail.md",
    date: "27 July, 2022",
    author: "Maulana",
    category: "Projects",
  },
]; 